import React, {useEffect, useState} from 'react'
import ReactInputMask from 'react-input-mask'
import axios from 'axios'
import {toast, ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import logo from '../../img/logo.png'
import './home.css'

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import {Autoplay, Keyboard, Mousewheel, Pagination} from "swiper";
import bir from '../../img/bir.png'
import ikki from '../../img/ikki.png'
import uch from '../../img/uch.png'
import turt from '../../img/turt.png'
import besh from '../../img/besh.png'
import olti from '../../img/olti.png'
import tg from '../../img/tg.png'
import ins from '../../img/in.png'
import yo from '../../img/yo.png'

const Home = () => {

    const [fullName, setFullName] = useState('')
    const [phone, setPhone] = useState('')
    const [course, setCourse] = useState('')

    const [alLCourses, setAllCourses] = useState([])
    const getCoursesList = () => {
        axios.get('https://back.roboticslab.uz/api/courses/list/brief')
            .then(res => {
                setAllCourses(res.data.data)
            }).catch(err => {
            console.log(err)
        })
    }

    const submitForm = (e) => {
        e.preventDefault()
        const formData = {
            name: fullName, phone, course
        }
        if (course) {
            axios.post('https://back.roboticslab.uz/api/courses/register', formData)
                .then( ()=> {
                    setFullName('')
                    setPhone('')
                    setCourse('')
                    toast.success("Muvaffaqqiyatli!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }).catch(() => {
                toast.error("Xatolik!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
        } else {
            toast.error("Xatolik!", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    useEffect(() => {
        getCoursesList()
    }, [])

    return (<div className='home-component'>
        <ToastContainer/>
        <div className='left'>
            <div className='sliders'>
                <Swiper
                    cssMode={true}
                    navigation={true}
                    pagination={{
                        clickable: true
                    }}
                    loop={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Pagination, Mousewheel, Keyboard, Autoplay]}
                    autoplay={{
                        delay: 3000, disableOnInteraction: false,
                    }}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='one-slide'>
                            <img src={bir} alt=''/>
                            <h2>Algoritmlash</h2>
                            <p>C++ asoslari. Olimpiada murakkab savollarini ko’rib chiqish. Robocontest.uz
                                platformasida masalalarni yechish va izohlash.</p>
                            <h2>Sunnatillo Hojiyev</h2>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='one-slide'>
                            <img src={ikki} alt=''/>
                            <h2>iOS dasturlash</h2>
                            <p>iOS operatsion sistemasida dasturlar yaratish. Swift dasturi bo’yicha tushunchalarni
                                ko’rib chiqish va izohlash.</p>
                            <h2>Shohzod Rajabov</h2>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='one-slide'>
                            <img src={uch} alt=''/>
                            <h2>C++ C#</h2>
                            <p>Dasturchi bo'lmoqchi bo'lgan yoki 0 dan o'rganmoqchi bo'layotgan barcha insonlar
                                uchun qaratilgan darslarimiz.</p>
                            <h2>Qarshiyev Asadbek</h2>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='one-slide'>
                            <img src={turt} alt=''/>
                            <h2>Robototexnika</h2>
                            <p>C++ asoslarini ko’rib chiqish. Qurilmalarga dastur yozish. Mustaqil mavzuda
                                qurilmalar yasash.</p>
                            <h2>Ochilov Humoyun</h2>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='one-slide'>
                            <img src={besh} alt=''/>
                            <h2>.NET</h2>
                            <p>C# asoslarini ko’rib chiqish. Ma'lumotlar bazasi bilan ishlash. Desktop va Web
                                ilovalar yaratish.</p>
                            <h2>Jasur Hasanboyev</h2>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='one-slide'>
                            <img src={olti} alt=''/>
                            <h2>Kiberxavfsizlik</h2>
                            <p>Shaxsiy ma’lumotlarni himoyalash. Raqamli texnologiyalardan yovuz maqsadda
                                foydalanishning oldini olish.</p>
                            <h2>Azizbek Xudoyberdiyev</h2>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='socials'>
                <h3>Biz ijtimoiy tarmoqlarda</h3>
                <div className='bottom'>
                    <a href='https://t.me/RoboticsLab' target="_blank">
                        <img src={tg} alt=''/>
                    </a>
                    <a href='https://www.instagram.com/roboticslabuz/' target="_blank">
                        <img src={ins} alt=''/>
                    </a>
                    <a href='https://www.youtube.com/c/ROBOTVuz' target="_blank">
                        <img src={yo} alt=''/>
                    </a>
                </div>
            </div>
        </div>

        <div className='right'>
            <img src={logo} alt='' className='logo'/>
            <form onSubmit={submitForm}>
                <h1>Ro’yxatdan o’tish</h1>
                <p>Xurmatli foydalanuvchi ro’yxatdan o’tgandan keyin, o’zimiz siz bilan bog’lanamiz</p>
                <input type='text' placeholder='Ism va familiyangiz' value={fullName}
                       onChange={(e) => setFullName(e.target.value)} required/>
                <ReactInputMask type='text' mask='\+\9\9\8 99 999 99 99' value={phone}
                                onChange={(e) => setPhone(e.target.value)} required placeholder='+998'/>
                <select value={course} onChange={(e) => setCourse(e.target.value)}>
                    <option hidden value={null}>Kurs turi</option>
                    {alLCourses?.map((item, index) => {
                        return (<option key={index} value={item.id}>{item.title}</option>)
                    })}
                </select>
                <button type='submit'>Ro’yxatdan o’tish</button>
            </form>
        </div>
    </div>)
}

export default Home