/* eslint-disable jsx-a11y/iframe-has-title */
import "./lesson.css";
import React from "react";

const Lesson = () => {
    const handleOnload = () => {
        setTimeout(() => {
                document.getElementById("registerBtn1").hidden = false;
            },
            360 * 1000);
    }
    return (
        <div className="lesson">
            <h6>Bu Sahifani Yopmang, Aks Holda Bepul Darsni Yo’qotasiz!</h6>
            <h3 className="intro">
                Qanday Qilib "Listening" dan Qisqa Vaqt Ichida Maksimal Tayyorlanish
                Kerak?
            </h3>
            <iframe


                /* video elementor ratio should be 500 : 281 */
                width="1300px"
                height="731px"
                className="elementor"
                src="https://player.vimeo.com/video/796078511?h=5cc23073aa&title=0&byline=0&portrait=0"
                allow="autoplay; fullscreen; picture-in-picture"
                onLoad={handleOnload}
            ></iframe>
            <a
                href={"https://roboticslab.uz"}
                target={"_blank"}
                className={"btn btn-primary mt-5"}
                hidden={true}
                id={"registerBtn1"} rel="noreferrer"
            >Kursga ro'yxatdan o'tish
            </a>
        </div>
    );
};

export default Lesson;
