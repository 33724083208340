import "./lesson.css";
import React from "react";

const AlgoLesson = () => {
    const handleOnload = () => {
        setTimeout(() => {
                document.getElementById("registerBtn").hidden = false;
            },
            360 * 1000);
    }
    return (
        <div className="lesson">
            <h6>Bu Sahifani Yopmang, Aks Holda Bepul Darsni Yo’qotasiz!</h6>
            <h3 className="intro">
                Dasturlarni qanday qilib tezashtirish kerak?
            </h3>
            <iframe
                title={"Algo lesson"}
                src="https://player.vimeo.com/video/828463518?h=5cc23073aa&title=0&byline=0&portrait=0"
                width="1920"
                height="1077"
                className="elementor"
                allow="autoplay; fullscreen; picture-in-picture"
                onLoad={handleOnload}
            >

            </iframe>
            <a
                href={"https://roboticslab.uz"}
                target={"_blank"}
                className={"btn btn-primary mt-5"}
                hidden={true}
                id={"registerBtn"} rel="noreferrer"
            >Kursga ro'yxatdan o'tish
            </a>
        </div>
    );
};

export default AlgoLesson;
