import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './components/home/Home';
import English from './components/english/English';
import Lesson from './components/eng-lesson/Lesson';
import Algorithm from './components/algorithm/Algorithm';
import AlgoLesson from './components/algo-lesson/Lesson';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/opt-b",
    element: <English />,
  },
  {
    path: "/opt-ins-b",
    element: <Lesson />,
  },
  {
    path: "/opt-a",
    element: <Algorithm/>,
  },
  {
    path: "/opt-ins-a",
    element: <AlgoLesson />,
  }
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


