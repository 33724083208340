import "./english.css";
import before from "../../img/before.png";
import axios from "axios";
import {useState} from "react";
import ReactInputMask from "react-input-mask";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const English = () => {
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/opt-ins-b`;
        navigate(path);
    };

    const [fullname, setFullname] = useState("");
    const [phone, setPhone] = useState("");

    const course = "26";

    const submitForm = async (e) => {
        e.preventDefault();

        const formData = {
            name: fullname,
            phone,
            course,
        };
        try {
            await axios.post(
                "https://back.roboticslab.uz/api/courses/register",
                formData
            );
        }catch {

        }
        try {

            const responseFromRoboticsApi = await axios.post(
                "https://roboticslab.uz/api/register",
                formData
            );

            if (responseFromRoboticsApi.status === 200) {
                setFullname("");
                setPhone("");
                toast.success("Muvaffaqqiyatli!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                setTimeout(() => {
                    routeChange();
                }, 1500);
            } else {
                toast.error("Xatolik!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch (e) {
            toast.error("Juda ko'p so'rov yubordingiz!", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            console.log(e);
        }
    };


    return (
        <div className="english">
            <ToastContainer/>
            <h6>Bu mutlaqo bepul dars, lekin vaqt chegaralangan</h6>
            <h3 className="intro">
                Qanday Qilib "Listening" dan Qisqa Vaqt Ichida Maksimal Tayyorlanish
                Kerak?
            </h3>
            <p>
                (Ushbu usullar orqali men o'z IELTS natijamni 1.5 barobarga ko'tara
                olganman)
            </p>

            <img
                style={{width: "100%"}}
                src={before}
                alt="salom"
            />

            <form onSubmit={submitForm} className="registration">
                <label htmlFor="name">
                    Ism <span>*</span>
                </label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Ismingizni kiriting"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    required
                    minLength={3}
                />
                <label htmlFor="phone">
                    Telefon <span>*</span>
                </label>

                <ReactInputMask
                    type="text"
                    mask="\+\9\9\8 99 999 99 99"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required

                    placeholder="+998"
                />
                <button type="submit">Davom etish</button>

            </form>
            <div className="cart">
                <h3>Ushbu Darsda Siz</h3>
                <h5>
                    <img
                        src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                        width="16px"
                        alt="✅"
                    />
                    Ko’pchilik o’rgatmaydigan 5 usulni bilib olasiz
                </h5>
                <h5>
                    <img
                        src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                        width="16px"
                        alt="✅"
                    />
                    Hamda bu usullar sizni mustaqil tayyorlanishizga imkon yaratadi
                </h5>
                <h5>
                    <img
                        src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                        width="16px"
                        alt="✅"
                    />
                    Darsdan to’laqonli foydalangan o’quvchilarda, eshitish va tushunish
                    bo’yicha muammo umuman bo’lmaydi
                </h5>
            </div>
        </div>
    );
};

export default English;
